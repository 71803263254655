// src/routes/PrivateRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute: React.FC = () => {
  const isAuthenticated = localStorage.getItem('token');
  
  return isAuthenticated ? <Outlet /> : <Navigate to="/agstadmin" replace />;
};

export default PrivateRoute;
