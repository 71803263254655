import API from '../utils/api';
import { LoginCredentials, AuthResponse } from '../types/types';

const login = async (credentials: LoginCredentials): Promise<AuthResponse> => {
    try {
        const response = await API.post<AuthResponse>('/login', credentials);
        
        return response.data;
    } catch (error: any) {
        // Assuming error.response.data is of type ErrorResponse
        const errorMessage = error.response?.data?.message || 'An unknown error occurred';
        throw new Error(errorMessage);
    }
};

const logout = async (): Promise<void> => {
    try {
        localStorage.removeItem('token');
        window.location.href = '/agstadmin';
    } catch (error: any) {
        // Assuming error.response.data is of type ErrorResponse
        const errorMessage = error.response?.data?.message || 'An unknown error occurred';
        throw new Error(errorMessage);
    }
};

export const authService = {
    login,
    logout,
};
