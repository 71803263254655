// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import { Login } from './components/auth/Login';
import AuthLayout from './layouts/AuthLayout';
import DashboardLayout from './layouts/DashboardLayout';
import PrivateRoute from './routes/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import { AppProvider } from './context/AppContext';
import PublicRoute from './routes/PublicRoute';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Contacts from './pages/Contacts';
import PublicLayout from './layouts/PublicLayout';
import Users from './pages/Users';

function App() {
  return (
    <AuthProvider>
      <AppProvider>
        <PrimeReactProvider>
          <Router>
            <Routes>
              <Route path="/" index element={<PublicLayout> <Contacts /> </PublicLayout>} />
              <Route path="/agstadmin" element={<PublicRoute />}>
                <Route path="/agstadmin" index element={<AuthLayout> <Login /> </AuthLayout>} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<DashboardLayout> <Dashboard /> </DashboardLayout>} />
                <Route path="/users" element={<DashboardLayout> <Users /> </DashboardLayout>} />
              </Route>
            </Routes>
          </Router>
        </PrimeReactProvider>
      </AppProvider>
    </AuthProvider>
  );
}

export default App;
